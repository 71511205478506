<template>
  <div class="bodywhite">
    <div class="header-light">
      <div class="container">
        <div class="row">
          <div class="col-2">
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-mainblue">Werte Erfassung</p>
          </div>
          <button class="col-2 col-right" @click="schliessenzuhome()">
              <img src="../assets/icons/close.svg">
          </button>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="paddingtop155">
        <p class="psemibold fontcolor-mainblue">Wie ist hoch ist deine Temperatur?</p>
        <form class="cursor col-right">
          <input type="text" id="werteabfrage-temperatur" name="temperatur" class="input" placeholder="0" ref="input" v-model="temperatur" />
          <label for="fname">°C</label>
        </form>
      </div>
    </div>

    <div class="stepnavigation backgroundcolor-beige">
      <div class="container">
        <button type="button" value="Submit" class="buttonXS backgroundcolor-white col-2 col-center center" @click="werteabfragetemperaturzuhome()">
          <img class="navicon:active" src="../assets/icons/arrow.svg">
        </button>
        <button type="button" value="Submit" class="buttonS fontcolor-white backgroundcolor-mainblue" @click="werteabfragetemperaturzuwerteabfragegewicht()">
          <p class="pbold display-inline fontcolor-white">Weiter</p>
          <img class="display-inline" src="../assets/icons/arrow_white.svg" alt="">
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '../router';

export default {
  components: {
  },
  data() {
      return {
        temperatur: null,
      };
    },
  mounted() {
    document.getElementById("werteabfrage-temperatur").focus();
  },
  methods: {
    ...mapActions(['setWert']),
    schliessenzuhome() {
      router.push({path: '/home'})
    },
    werteabfragetemperaturzuhome() {
      router.push({path: '/home'})
    },
    werteabfragetemperaturzuwerteabfragegewicht() {
      if(this.temperatur){
        this.setWert({
          name: "temperatur", 
          wert: this.temperatur,
        });
        setTimeout(()=> router.push({ path: '/werteabfragegewicht' }), 100);
        //router.push({path: '/werteabfragegewicht'});
      }
    },
  } 
}
</script>


<style scoped>

.stepnavigation {
  background-color: #F6F6F6;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 95px;
  bottom: 0;
  padding-top: 15px;
  z-index: 2;
}

.input {
    color: #0B1010;
    text-align: right;
    font-size: 65px;
    font-weight: bold;
    width: 90%;
    border: none;
    height: 320px;
}

.input:focus {
outline: none;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
</style>